import { javascript } from '@api/index'
import { clone } from '@avvoka/shared'
import { useHydration } from '@stores/utils'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export type DocumentViewType = ReturnType<typeof useDocumentViewStore>
export const allDocumentViewStores = ref<Array<DocumentViewType>>([])

type ProfileUsers = ReadonlyArray<{
  id: number
  approve: Backend.Models.ApproveRights
  edit: Backend.Models.EditRights
  invite: Backend.Models.InviteRights
  sign: Backend.Models.SignRights
  user: Pick<Backend.Models.User, 'id' | 'firstname' | 'lastname' | 'email'>
}>

type ResponseData = {
  comments: Array<Backend.Models.Comment>
  profile_users: ProfileUsers
} & Backend.Models.Model

export const createDocumentViewStore = (uniqueId: string | number) => {
  const documentViewStore = defineStore(
    'documentView-store-' + String(uniqueId),
    () => {
      const hydration = useHydration<ResponseData>(javascript.documentView)
      const hydrateById = (documentId: number) => {
        return hydration.hydrate({ document_id: documentId }, [], true)
      }
      allDocumentViewStores.value.push(
        documentViewStore as unknown as DocumentViewType
      )

      return {
        ...hydration,
        hydrateById,
        profileUsers: hydration.hydratedComputed('profile_users'),
        comments: computed<ReadonlyArray<Backend.Models.Comment.Parsed>>(() => {
          return clone(hydration.hydratedData.value!.comments).map(
            (comment) => {
              let parsedComment = clone(
                comment
              ) as unknown as Backend.Models.Comment.Parsed
              parsedComment.message = JSON.parse(comment.message as string)
              parsedComment.raw = new WeakRef(comment)
              parsedComment.message.forEach((message) => {
                // TODO: Verify if this is still needed
                if (
                  message.archived_by &&
                  typeof message.archived_by === 'string'
                )
                  message.archived_by = parseInt(message.archived_by)
              })
              return parsedComment
            }
          )
        }),
      }
    }
  )
  return documentViewStore
}

export const useDocumentViewStore = createDocumentViewStore('current')
